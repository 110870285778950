
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import CustomerEntityModule from '@/store/customers/entity';
import CustomersModule from '@/store/customers';
import CustomersHelpersModule from '@/store/customers/helpers';
import AppTable from '@/components/ui/table/Table.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    TextPhone,
    AppTable,
    TitleReturn,
    TabsNav,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId as string;
  },
})
export default class CustomerHelpers extends Vue {
  customerId = '';

  get isTest(): boolean {
    return CustomerEntityModule.model.isTest;
  }

  get settings(): PageInterface {
    return CustomersHelpersModule.pageSettings;
  }

  get titlePage(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  get tabsNav(): TabsNavInterface {
    return CustomerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return CustomerEntityModule.allTabs.helpers?.id;
  }

  get actionsHandler(): {} | undefined {
    return CustomersHelpersModule.pageSettings.actionsHandler;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }

  toAdd(): void {
    this.$router.push({ name: 'helper_add', params: { customerId: this.customerId } });
  }

  selectAmount(value: string): void {
    CustomersHelpersModule.updatePageAmountItems(value);
  }

  mounted(): void {
    CustomerEntityModule.initTabsItems();
    CustomerEntityModule.initTitle(this.customerId);
    CustomersHelpersModule.initListById(this.customerId);
  }
}
